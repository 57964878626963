import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Button, Badge, Card, CardGroup, ListGroup, Nav, Navbar, NavDropdown, Form, Image } from 'react-bootstrap';
import {
    Box,
    Collapse,
    Fab,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    CircularProgress,
    Skeleton,
    // getIconButtonUtilityClass
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { center } from '@turf/turf';
// Import styling 
import "../components/Stat.css";
import ReactGA from "react-ga4";

let sphere = null;
let map = null;
let displayKey = null;
let last_pv_code = null;
let last_ap_code = null;
let last_tb_code = null;
let last_pv_geometry = null;
let last_ap_geometry = null;
let last_tb_geometry = null;

let last_rid_code = null;
let last_rid_geometry = null;

let last_basin_code = null;
let last_basin_sub_code = null;
let last_mainBasin_geometry = null;

let layer_dri_province = null;
let layer_dri_amphoe = null;
let layer_dri_tambon = null;
let layer_dri_irr = null;
let layer_dri_mainBasin = null;


// let currentDate = new Date();
// let year = new Date(currentDate.getFullYear(), 0, 1);
// let days = Math.floor((currentDate - year) / (24 * 60 * 60 * 1000));
// let week = Math.ceil(( currentDate.getDay() - 1 + days) / 7);

Date.prototype.getWeek = function (dowOffset) {
    /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

    dowOffset = typeof (dowOffset) == 'number' ? dowOffset : 0; //default dowOffset to zero
    var newYear = new Date
    //(this.getFullYear(), 0, 1);
    console.log(newYear)
    var newYear_n = new Date(newYear.getFullYear(), 0, 1)
    console.log(newYear_n);
    var day = newYear_n.getDay() - dowOffset; //the day of week the year begins on
    day = (day >= 0 ? day : day + 7);
    var daynum = Math.floor((this.getTime() - newYear_n.getTime() -
        (this.getTimezoneOffset() - newYear_n.getTimezoneOffset()) * 60000) / 86400000) + 1;
    var weeknum;
    //if the year starts before the middle of a week
    if (day < 4) {
        weeknum = Math.floor((daynum + day - 1) / 7) + 1;
        if (weeknum > 52) {
            newYear_n = new Date(this.getFullYear() + 1, 0, 1);
            nday = nYear.getDay() - dowOffset;
            nday = nday >= 0 ? nday : nday + 7;
            /*if the next year starts before the middle of
              the week, it is week #1 of that year*/
            weeknum = nday < 4 ? 1 : 53;
        }
    }
    else {
        weeknum = Math.floor((daynum + day - 1) / 7);
    }
    return weeknum;

};

let mydate = new Date();

mydate.setDate(mydate.getDate() - 7);
console.log(mydate);


//alert(mydate.getWeek() - 1);
let week = (mydate.getWeek());
console.log(week)

// let first = mydate.getDate() - mydate.getDay(); // First day is the day of the month - the day of the week
// console.log(first)
// let last = first + 6; // last day is the first day + 6


// let firstday = new Date(mydate.setDate(first)).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })
// console.log(firstday)
// let lastday = new Date(mydate.setDate(last)).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })
// console.log(lastday)


var curr = new Date; // get current date
var first = curr.getDate() - mydate.getDay(); // First day is the day of the month - the day of the week
console.log(first)
var last = first + 6; // last day is the first day + 6

// var firstday = new Date(mydate.setDate(first)).toUTCString();
// console.log(firstday)
// var lastday = new Date(curr.setDate(last)).toUTCString();
// console.log(lastday)



let date_today = new Date();
// console.log(`The current day index of the week is: ${date_today.getDay()-1}`);

// console.log(`The current date of the week is: ${date_today.getDate()-7}`);

let first_day_of_the_week = new Date(date_today.setDate(date_today.getDate() - 7
    - date_today.getDay() + 1));
let last_day_of_the_week = new Date(date_today.setDate(date_today.getDate()
    - date_today.getDay() + 7));
console.log(`The first date of the week is: ${first_day_of_the_week}`);
console.log(`The last day of the week is: ${last_day_of_the_week}`);

var firstday = first_day_of_the_week.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })
// console.log(firstday)
var lastday = last_day_of_the_week.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })
// console.log(lastday)



const Stat = () => {

    const [fetchedData, setFetchedData] = useState([]);
    const [pvTn, setPvTn] = useState(null);
    const [apTn, setApTn] = useState(null);
    const [tbTn, setTbTn] = useState(null);
    const [IrrLv1Tn, setIrrLv1Tn] = useState(null);
    const [IrrLv2Tn, setIrrLv2Tn] = useState(null);
    const [BasinLv1Tn, setBasinLv1Tn] = useState(null);
    const [BasinLv2Tn, setBasinLv2Tn] = useState(null);


    const [typeValue, setTypeValue] = useState(type[0]);
    const [provinceValue, setProvinceValue] = useState(provinces[0]);
    const [ridOfficeValue, setridOfficeValue] = useState(rid_offices[0]);
    const [mainBasinValue, setmainBasinValue] = useState(mainBasin[0]);


    const [result_map, setresult_map] = useState();
    const [tableHeaderText, setTableHeaderText] = useState('จังหวัด');


    const getDamsFromRID = async (url) => {
        const response = await fetch(url, { method: 'POST' });
        const result = await response.json();
        // console.log(result.regions)
        for (let i = 0; i < result.regions.length; i++) {
            let region = result.regions[i];
            for (let j = 0; j < region.dams.length; j++) {
                // console.log(region.dams[j])
                let dam = region.dams[j];
                let detail = `<span style='font-weight: bold; font-size: 12px'>วันที่ : </span> <span>${dam.DMD_Date}</span><br>`;
                detail += `<span style='font-weight: bold; font-size: 12px'>ความจุ : </span> <span>${dam.DAM_QMax} ล้าน ลบ.ม.</span><br>`;
                detail += `<span style='font-weight: bold; font-size: 12px'>ปริมาณน้ำในอ่าง : </span> <span>${dam.DMD_QUse} (${dam.PERCENT_DMD_QUse}%)</span><br>`;
                detail += `<span style='font-weight: bold; font-size: 12px'>ปริมาณน้ำใช้การ : </span> <span>${dam.v_QUsage} ล้าน ลบ.ม.</span><br>`;
                detail += `<span style='font-weight: bold; font-size: 12px'>ปริมาณน้ำไหลเข้า : </span> <span>${dam.DMD_Inflow} ล้าน ลบ.ม.</span><br>`;
                detail += `<span style='font-weight: bold; font-size: 12px'>ปริมาณน้ำระบาย : </span> <span>${dam.DMD_Outflow} ล้าน ลบ.ม.</span><br>`;

                let icon = '/color-dam1.png';
                if (parseFloat(dam.PERCENT_DMD_QUse) > 100) { icon = '/color-dam5.png'; }
                else if (parseFloat(dam.PERCENT_DMD_QUse) > 81) { icon = '/color-dam4.png'; }
                else if (parseFloat(dam.PERCENT_DMD_QUse) > 51) { icon = '/color-dam3.png'; }
                else if (parseFloat(dam.PERCENT_DMD_QUse) > 31) { icon = '/color-dam2.png'; }
                let marker = new sphere.Marker({ lon: dam.DAM_Lon, lat: dam.DAM_Lat },
                    {
                        title: dam.DAM_Name,
                        detail: detail,
                        icon: {
                            html: `<img width=22 height=26 src='${icon}'>`
                            // , offset: { x: 14, y: -12 }
                        },
                    });
                console.log(marker)
                map.Overlays.add(marker);
            }//end for
        }//end for

    };


    const getRsvmiddlesFromRID = async (url) => {
        const response = await fetch(url, { method: 'POST' });
        const result = await response.json();
        console.log(result)
        for (let i = 0; i < result.region.length; i++) {
            let region = result.region[i];
            for (let j = 0; j < region.reservoir.length; j++) {
                let reservoir = region.reservoir[j];
                // console.log(reservoir);
                let detail = `<span style='font-weight: bold; font-size: 12px'>วันที่ : </span> <span>${reservoir.date}</span><br>`;
                detail += `<span style='font-weight: bold; font-size: 12px'>จังหวัด : </span> <span>${reservoir.tprov}</span><br>`;
                detail += `<span style='font-weight: bold; font-size: 12px'>ความจุ : </span> <span>${reservoir.cap_resv} ล้าน ลบ.ม.</span><br>`;
                detail += `<span style='font-weight: bold; font-size: 12px'>ปริมาณน้ำในอ่าง : </span> <span>${reservoir.qdisc_curr} (${reservoir.percent_resv_curr}%)</span><br>`;


                let icon = '/yellow-dot.png';
                if (parseFloat(reservoir.percent_resv_curr) > 100) { icon = '/carmine-dot.png'; }
                else if (parseFloat(reservoir.percent_resv_curr) > 81) { icon = '/red-dot.png'; }
                else if (parseFloat(reservoir.percent_resv_curr) > 51) { icon = '/blue-dot.png'; }
                else if (parseFloat(reservoir.percent_resv_curr) > 31) { icon = '/green-dot.png'; }
                let marker = new sphere.Marker({ lon: reservoir.cresv_lng, lat: reservoir.cresv_lat },
                    {
                        title: reservoir.nresv,
                        detail: detail,
                        icon: {
                            html: `<img width=20 height=20 src='${icon}'>`,
                            // offset: { x: 10, y: -10 }
                        },
                    });
                map.Overlays.add(marker);
            }//end for




        }//end for

    };



    useEffect(() => {
        const TRACKING_ID = "G-6VFTN9NWRE"
        ReactGA.initialize(TRACKING_ID)
        ReactGA.send({ hitType: "pageview", page: "/Stat", title: "Stat" });
        let urlProvince = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/province`;
        getData(urlProvince, 'pv');

        let result_map

        getDamsFromRID(`https://app.rid.go.th/reservoir/api/dams`);
        //getRsvmiddlesFromRID(`https://app.rid.go.th/reservoir/api/rsvmiddles`);


        // var osmHOT = new sphere.Layer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
        //     zoomRange: { min: 1, max: 18},
        //     attribution: '© OpenStreetMap contributors, Tiles style by Humanitarian OpenStreetMap Team hosted by OpenStreetMap France'
        // });


        //-- sphere --//
        sphere = window.sphere;
        map = new sphere.Map({ placeholder: document.getElementById('map-sphere'), zoomRange: { min: 0, max: 18 }, });

        map._this = this;



        map.Event.bind("ready", function () {
            //map.bound({ minLon: 97.343699, minLat: 5.612738, maxLon: 105.636781, maxLat: 20.464926 }, { padding: { top: 7, bottom: 7 } });
            map.zoom(4.4);
            map.Ui.DPad.visible(false);
            map.Ui.Geolocation.visible(false);
            map.Ui.LayerSelector.visible(false);





            layer_dri_province = new sphere.Layer('dri:province_with_c_dri', {
                type: sphere.LayerType.WMS,
                url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms",
                zoomRange: { min: 1, max: 5.5 },
                zIndex: 5,
                opacity: 0.65,
                id: 'layer_dri_province',
                //extraQuery:'CQL_FILTER' + "=='ยโสธร'".replaceAll("=='ยโสธร'","=pv_tn='ยโสธร'")
            });
            map.Layers.add(layer_dri_province);
            // let layer_dri_amphoe = new sphere.Layer('dri:amphoe_with_c_dri', {
            //     type: sphere.LayerType.WMS,
            //     url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
            //     zoomRange: { min: 6.51, max: 8.9 },
            //     zIndex: 6,
            //     opacity: 0.65,
            //     id: 'layer_dri_amphoe',
            //     //extraQuery:'CQL_FILTER' + "=='ยโสธร'".replaceAll("=='ยโสธร'","=pv_tn='ยโสธร'")
            //     //extraQuery:'CQL_FILTER' +'=pv_tn' + '=' +"'" + 'ยโสธร' +"'"
            //     //extraQuery: 'CQL_FILTER' + '=pv_tn' + '=' + "'" + pv_tn + "'"

            // });
            // map.Layers.add(layer_dri_amphoe);
            // let layer_dri_tambon = new sphere.Layer('dri:tambon_with_c_dri', {
            //     type: sphere.LayerType.WMS,
            //     url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
            //     zoomRange: { min: 8.91, max: 18 },
            //     zIndex: 7,
            //     opacity: 0.65,
            //     id: 'layer_dri_tambon',
            //     //extraQuery:'CQL_FILTER' + "=='ป่าติ้ว'".replaceAll("=='ป่าติ้ว'","=ap_tn='ป่าติ้ว'")
            //     //extraQuery:'CQL_FILTER' +'=ap_tn' + '=' +"'" + 'ป่าติ้ว' +"'" +'and'+ ' '+ 'tb_tn' + '=' +"'" + 'เชียงเพ็ง' +"'"
            // });
            // map.Layers.add(layer_dri_tambon);
            // let layer_dri_tambon_sub = new sphere.Layer('dri:tambon_with_c_dri', {
            //     type: sphere.LayerType.WMS,
            //     url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
            //     zoomRange: { min: 8.91, max: 18 },
            //     zIndex: 7,
            //     opacity: 0.65,
            //     id: 'layer_dri_tambonsub',
            //     //extraQuery:'CQL_FILTER' + "=='เชียงเพ็ง'".replaceAll("=='เชียงเพ็ง'","=tb_tn='เชียงเพ็ง'")
            //     extraQuery:'CQL_FILTER' +'=tb_tn' + '=' +"'" + 'โพธิ์ไทร' +"'"
            // });
            // map.Layers.add(layer_dri_tambon_sub);
        });
    }, [])

    const getData = async (url, admin) => {
        const response = await fetch(url);
        const result = await response.json();
        // console.log(result.data.jsonFeatures)

        displayKey = 'pv_tn';
        if (admin == 'ap') { displayKey = 'ap_tn' }
        else if (admin == 'tb') { displayKey = 'tb_tn' }
        else if (admin == 'irr') { displayKey = 'office' }
        else if (admin == 'irrLv2') { displayKey = 'owner' }
        else if (admin == 'basin') { displayKey = 'mbasin_t' }
        else if (admin == 'basinLv2') { displayKey = 'sb_name_t' }


        let result_map = result.data.jsonFeatures
        // setresult_map(result_map)
        setFetchedData(result.data.jsonFeatures)

    };




    const drillDown = (pv_code, ap_code, tb_code, geometry, pv_tn, ap_tn, tb_tn, code, ridOffice, ridProject, mb_code, mbasin_t, sb_name_t, sb_code) => {
        // console.log(pv_code, ap_code, tb_code, code);
        console.log(pv_code, ap_code, tb_code, geometry, pv_tn, ap_tn, tb_tn, code, ridOffice, ridProject, mb_code, mbasin_t, sb_code);

        if (tb_code) {
            //Do notthing
        }
        else if (ap_code) {
            setTableHeaderText('ตำบล')
            last_ap_code = ap_code;
            last_ap_geometry = geometry;
            const urlTambon = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/tambon?province=${pv_code}&amphoe=${ap_code}`;
            getData(urlTambon, 'tb');
            // if (ap_tn == '') {
            //     let layer_dri_amphoe = new sphere.Layer('dri:amphoe_with_c_dri', {
            //         type: sphere.LayerType.WMS,
            //         url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
            //         zoomRange: { min: 6.51, max: 8.9 },
            //         zIndex: 6,
            //         opacity: 0.65,
            //         id: 'layer_dri_amphoe',
            //         extraQuery: 'CQL_FILTER' + '=pv_tn' + '=' + "'" + pv_tn + "'"
            //     });
            //     map.Layers.remove(layer_dri_amphoe);
            //     let layer_dri_tambon = new sphere.Layer('dri:tambon_with_c_dri', {
            //         type: sphere.LayerType.WMS,
            //         url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
            //         zoomRange: { min: 8.91, max: 18 },
            //         zIndex: 7,
            //         opacity: 0.65,
            //         id: 'layer_dri_tambon',
            //         //extraQuery:'CQL_FILTER' + "=='ป่าติ้ว'".replaceAll("=='ป่าติ้ว'","=ap_tn='ป่าติ้ว'")
            //         extraQuery: 'CQL_FILTER' + '=ap_tn' + '=' + "'" + ap_tn + "'"
            //     });
            //     map.Layers.remove(layer_dri_tambon);
            // } else {
            layer_dri_tambon = new sphere.Layer('dri:tambon_with_c_dri', {
                type: sphere.LayerType.WMS,
                url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
                zoomRange: { min: 8.0, max: 18 },
                zIndex: 7,
                opacity: 0.65,
                id: 'layer_dri_tambon',
                //extraQuery:'CQL_FILTER' + "=='ป่าติ้ว'".replaceAll("=='ป่าติ้ว'","=ap_tn='ป่าติ้ว'")
                extraQuery: 'CQL_FILTER' + '=ap_tn' + '=' + "'" + ap_tn + "'"
                //extraQuery: 'CQL_FILTER' + '=ap_tn' + '=' + "'" + 'ป่าติ้ว' + "'" + 'and' + ' ' + 'tb_tn' + '=' + "'" + 'เชียงเพ็ง' + "'"
            });
            map.Layers.add(layer_dri_tambon);

            // }
        }
        else if (pv_code) {
            setTableHeaderText('อำเภอ')
            last_pv_code = pv_code;
            console.log("555")
            last_pv_geometry = geometry;
            const urlAmphoe = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/amphoe?province=${pv_code}`;
            getData(urlAmphoe, 'ap');


            layer_dri_amphoe = new sphere.Layer('dri:amphoe_with_c_dri', {
                type: sphere.LayerType.WMS,
                url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
                zoomRange: { min: 0, max: 9 },
                zIndex: 6,
                opacity: 0.65,
                id: 'layer_dri_amphoe',
                extraQuery: 'CQL_FILTER' + '=pv_tn' + '=' + "'" + pv_tn + "'"

            });

            map.Layers.add(layer_dri_amphoe);
            console.log("no")
            console.log('layer_dri_amphoe', layer_dri_amphoe)


        }


        else if (code) {
            last_rid_code = code;
            last_rid_geometry = geometry;
            setTableHeaderText('โครงการ')
            const urlIrrLv2 = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/irrigation_area_owner?office=${code}`;
            getData(urlIrrLv2, 'irrLv2');


            map.Layers.remove(layer_dri_irr);
            layer_dri_irr = new sphere.Layer('dri:irrigation_area_owner_with_c_dri_', {
                type: sphere.LayerType.WMS,
                url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
                zoomRange: { min: 4, max: 15 },
                zIndex: 6,
                opacity: 0.65,
                id: 'layer_dri_irr',
                extraQuery: 'CQL_FILTER' + '=code' + '=' + "'" + code + "'"

            });

            map.Layers.add(layer_dri_irr);

        }


        else if (sb_code) {
            //do nothing

        }

        else if (mb_code) {
            last_basin_code = mb_code;
            last_mainBasin_geometry = geometry;
            setTableHeaderText('ลุ่มน้ำย่อย')
            const urlMainBasinLv2 = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/mainbasin_onwr_basin_sub?mb_code=${mb_code}`;
            getData(urlMainBasinLv2, 'basinLv2');

            map.Layers.remove(layer_dri_mainBasin);
            layer_dri_mainBasin = new sphere.Layer('dri:mainbasin_onwr_basin_sub_with_c_dri_', {
                type: sphere.LayerType.WMS,
                url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
                zoomRange: { min: 4, max: 15 },
                zIndex: 6,
                opacity: 0.65,
                id: 'layer_dri_mainBasin',
                extraQuery: 'CQL_FILTER' + '=mb_code' + '=' + "'" + mb_code + "'"

            });
            map.Layers.add(layer_dri_mainBasin);

        }

        if (geometry) {
            //-- fit extend by estimate bbox --//
            let lngs = [];
            let lats = [];
            let coords = geometry.coordinates[0][0]
            for (let i = 0; i < coords.length; i++) {
                lngs.push(coords[i][0])
                lats.push(coords[i][1])

            }//end for
            //-- fit extend by estimate bbox fixed for some provinces --//
            for (let i = 0; i < geometry.coordinates.length; i++) {
                let lv1 = geometry.coordinates[i];
                for (let j = 0; j < lv1.length; j++) {
                    let lv2 = lv1[j];
                    lngs.push(lv2[0][0])
                    lats.push(lv2[0][1])
                }
            }//end for

            map.bound({ minLon: Math.min(...lngs), minLat: Math.min(...lats), maxLon: Math.max(...lngs), maxLat: Math.max(...lats) }, { padding: { top: 7, bottom: 7 } });
            //map.Layers.remove(layer_dri_amphoe);
            //map.Layers.remove(layer_dri_province);

        }
        setPvTn(pv_tn)
        setApTn(ap_tn)
        setTbTn(tb_tn)
        setIrrLv1Tn(ridOffice)
        setIrrLv2Tn(ridProject)
        setBasinLv1Tn(mbasin_t)
        setBasinLv2Tn(sb_name_t)
    }

    const drillMainBasin = (mbasin_t, mb_code) => {
        setIrrLv1Tn(null)
        setIrrLv2Tn(null)

        setTableHeaderText('ลุ่มน้ำหลัก')
        let urlMainBasin = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/mainbasin_onwr_basin-search?mb_code=${mb_code}`;
        if (mbasin_t == "ลุ่มน้ำหลักทั้งหมด") {
            urlMainBasin = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/mainbasin_onwr_basin`
            map.Layers.remove(layer_dri_mainBasin);
            layer_dri_mainBasin = new sphere.Layer('dri:mainbasin_onwr_basin_with_c_dri_', {
                type: sphere.LayerType.WMS,
                url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
                zoomRange: { min: 4, max: 15 },
                zIndex: 6,
                opacity: 0.65,
                id: 'layer_dri_mainBasin'
            });
            map.Layers.add(layer_dri_mainBasin);
        }//end if
        getData(urlMainBasin, 'basin')

    }


    const drillRidOffice = (office, code) => {
        console.log(office, code)
        setIrrLv1Tn(null)
        setIrrLv2Tn(null)
        setTableHeaderText('สำนักงานชลประทาน')
        let urlIrr = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/irrigation_area_owner-search?code_=${code}`;
        if (office == "สำนักชลประทานทั่วประเทศ") {
            urlIrr = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/irrigation_area_office`;
            layer_dri_irr = new sphere.Layer('dri:irrigation_area_owner_with_c_dri_', {
                type: sphere.LayerType.WMS,
                url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
                zoomRange: { min: 4, max: 15 },
                zIndex: 6,
                opacity: 0.65,
                id: 'layer_dri_irr',
            });
            map.Layers.add(layer_dri_irr);

        }//end if
        getData(urlIrr, 'irr')

    }

    const drillThailand = (pv_tn, ap_tn) => {
        setTableHeaderText('จังหวัด')
        console.log(pv_tn)
        let urlProvince = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/province-search?province=${pv_tn == 'ประเทศไทย' ? '' : pv_tn}`;
        //let urlProvince = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/province`;
        getData(urlProvince, 'pv')
        console.log(urlProvince)
        map.bound({ minLon: 97.343699, minLat: 5.612738, maxLon: 105.636781, maxLat: 20.464926 }, { padding: { top: 7, bottom: 7 } });
        //console.log('555')
        setPvTn(null)
        setApTn(null)
        setTbTn(null)
        setIrrLv1Tn(null)
        setIrrLv2Tn(null)
        setBasinLv1Tn(null)
        setBasinLv2Tn(null)
        last_pv_code = null;
        last_ap_code = null;
        last_tb_code = null;

        try { map.Layers.remove(layer_dri_province); } catch (error) { }
        try { map.Layers.remove(layer_dri_amphoe); } catch (error) { }
        try { map.Layers.remove(layer_dri_tambon); } catch (error) { }
        try { map.Layers.remove(layer_dri_irr); } catch (error) { }
        try { map.Layers.remove(layer_dri_mainBasin); } catch (error) { }

        if (typeValue.id == 1) {
            layer_dri_province = new sphere.Layer('dri:province_with_c_dri', {
                type: sphere.LayerType.WMS,
                url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms",
                zoomRange: { min: 1, max: 5.5 },
                zIndex: 5,
                opacity: 0.65,
                id: 'layer_dri_province',
            });
            map.Layers.add(layer_dri_province);
        }
        else if (typeValue.id == 2) {
            layer_dri_irr = new sphere.Layer('dri:irrigation_area_owner_with_c_dri_', {
                type: sphere.LayerType.WMS,
                url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
                zoomRange: { min: 4, max: 15 },
                zIndex: 6,
                opacity: 0.65,
                id: 'layer_dri_irr',
            });
            map.Layers.add(layer_dri_irr);
        }
        else if (typeValue.id == 3) {
            layer_dri_mainBasin = new sphere.Layer('dri:mainbasin_onwr_basin_with_c_dri_', {
                type: sphere.LayerType.WMS,
                url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
                zoomRange: { min: 4, max: 15 },
                zIndex: 6,
                opacity: 0.65,
                id: 'layer_dri_mainBasin',
            });
            map.Layers.add(layer_dri_mainBasin);
        }


        //setTbTn(tb_tn)

        if (pv_tn == 'ประเทศไทย') {
            setProvinceValue(provinces[0])
            //setPvTn(pv_tn)
        }





    }

    const drillProvince = async () => {
        setTableHeaderText('อำเภอ')
        //console.log(pv_tn)
        //const urlAmphoe = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/amphoe?province=${pv_tn == last_pv_code ? '' : pv_tn}`;
        const urlAmphoe = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/amphoe?province=${last_pv_code}`;
        getData(urlAmphoe, 'ap');
        console.log(urlAmphoe)
        //console.log(urlAmphoe.replace('https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/amphoe?province=', ''))

        let last_pv_code_data = urlAmphoe.replace('https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/amphoe?province=', '')
        console.log(last_pv_code_data)

        setApTn(null)
        setTbTn(null)
        // last_pv_code = null;
        //console.log(last_pv_code)
        last_ap_code = null;
        last_tb_code = null;


        //-- fit extend by estimate bbox --//
        let lngs = [];
        let lats = [];
        let coords = last_pv_geometry.coordinates[0][0]

        for (let i = 0; i < coords.length; i++) {
            lngs.push(coords[i][0])
            lats.push(coords[i][1])
        }//end for
        map.bound({ minLon: Math.min(...lngs), minLat: Math.min(...lats), maxLon: Math.max(...lngs), maxLat: Math.max(...lats) }, { padding: { top: 7, bottom: 7 } });

        let layer_dri_tambon = new sphere.Layer('dri:tambon_with_c_dri', {
            type: sphere.LayerType.WMS,
            url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
            zoomRange: { min: 8.91, max: 18 },
            zIndex: 7,
            opacity: 0.65,
            id: 'layer_dri_tambon',
            //extraQuery:'CQL_FILTER' + "=='ป่าติ้ว'".replaceAll("=='ป่าติ้ว'","=ap_tn='ป่าติ้ว'")
            //extraQuery: 'CQL_FILTER' + '=ap_tn' + '=' + "'" + ap_tn + "'"
        });

        try { map.Layers.remove(layer_dri_tambon); } catch (error) { }


        if (urlAmphoe != '') {
            //setProvinceValue(provinces[0])

            const response = await axios.get(urlAmphoe);
            // console.log(response.data.data.jsonFeatures);

            //console.log(pv_tn)
            console.log('urlAmphoe')

            let layer_dri_tambon = new sphere.Layer('dri:tambon_with_c_dri', {
                type: sphere.LayerType.WMS,
                url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
                zoomRange: { min: 8.91, max: 18 },
                zIndex: 7,
                opacity: 0.65,
                id: 'layer_dri_tambon',
                //extraQuery:'CQL_FILTER' + "=='ป่าติ้ว'".replaceAll("=='ป่าติ้ว'","=ap_tn='ป่าติ้ว'")
                //extraQuery: 'CQL_FILTER' + '=ap_tn' + '=' + "'" + ap_tn + "'"
            });

            try { map.Layers.remove(layer_dri_tambon); } catch (error) { }
            //setPvTn(pv_tn)
            //setApTn(ap_tn)
        } else {
            console.log('666')

        }


    }

    const drillAmphoe = async () => {
        const urlTambon = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/tambon?province=${last_pv_code}&amphoe=${last_ap_code}`;
        getData(urlTambon, 'tb');
        console.log(urlTambon)
        // setPvTn(pv_tn)
        // setApTn(ap_tn)
        setTbTn(null)
        //last_ap_code = null;
        last_tb_code = null;

        //-- fit extend by estimate bbox --//
        let lngs = [];
        let lats = [];
        let coords = last_ap_geometry.coordinates[0][0]
        for (let i = 0; i < coords.length; i++) {
            lngs.push(coords[i][0])
            lats.push(coords[i][1])
        }//end for
        map.bound({ minLon: Math.min(...lngs), minLat: Math.min(...lats), maxLon: Math.max(...lngs), maxLat: Math.max(...lats) }, { padding: { top: 7, bottom: 7 } });

        if (urlTambon != '') {
            //setProvinceValue(provinces[0])

            const response = await axios.get(
                urlTambon);
            console.log(response.data.data.jsonFeatures);

            //console.log(pv_tn)
            console.log('urlTambon')
            // let layer_dri_tambon = new sphere.Layer('dri:tambon_with_c_dri', {
            //     type: sphere.LayerType.WMS,
            //     url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
            //     zoomRange: { min: 8.91, max: 18 },
            //     zIndex: 7,
            //     opacity: 0.65,
            //     id: 'layer_dri_tambon',
            //     //extraQuery:'CQL_FILTER' + "=='ป่าติ้ว'".replaceAll("=='ป่าติ้ว'","=ap_tn='ป่าติ้ว'")
            //     //extraQuery: 'CQL_FILTER' + '=ap_tn' + '=' + "'" + ap_tn + "'"
            //     //extraQuery: 'CQL_FILTER' + '=ap_tn' + '=' + "'" + 'ป่าติ้ว' + "'" + 'and' + ' ' + 'tb_tn' + '=' + "'" + 'เชียงเพ็ง' + "'"
            // });
            // map.Layers.remove(layer_dri_tambon);

            //setPvTn(pv_tn)
            //setApTn(ap_tn)
        } else {
            console.log('666')

        }


    }

    const drillTambon = (pv_tn, ap_tn, tb_tn) => {
        const urlTambonsub = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/tambonsub?province=${last_pv_code}&amphoe=${last_ap_code}&tambon=${last_tb_code}`;
        getData(urlTambonsub, 'tb');
        // setPvTn(pv_tn)
        // setApTn(ap_tn)
        // setTbTn(tb_tn)
        last_tb_code = null;

        //-- fit extend by estimate bbox --//
        let lngs = [];
        let lats = [];
        let coords = last_tb_geometry.coordinates[0][0]
        for (let i = 0; i < coords.length; i++) {
            lngs.push(coords[i][0])
            lats.push(coords[i][1])
        }//end for



        map.bound({ minLon: Math.min(...lngs), minLat: Math.min(...lats), maxLon: Math.max(...lngs), maxLat: Math.max(...lats) }, { padding: { top: 7, bottom: 7 } });
    }

    const drillIrrLv1 = () => {
        setIrrLv2Tn(null)
        let geometry = last_rid_geometry;
        if (geometry) {
            //-- fit extend by estimate bbox --//
            let lngs = [];
            let lats = [];
            let coords = geometry.coordinates[0][0]
            for (let i = 0; i < coords.length; i++) {
                lngs.push(coords[i][0])
                lats.push(coords[i][1])

            }//end for
            //-- fit extend by estimate bbox fixed for some provinces --//
            for (let i = 0; i < geometry.coordinates.length; i++) {
                let lv1 = geometry.coordinates[i];
                for (let j = 0; j < lv1.length; j++) {
                    let lv2 = lv1[j];
                    lngs.push(lv2[0][0])
                    lats.push(lv2[0][1])
                }
            }//end for

            map.bound({ minLon: Math.min(...lngs), minLat: Math.min(...lats), maxLon: Math.max(...lngs), maxLat: Math.max(...lats) }, { padding: { top: 7, bottom: 7 } });


        }
    }

    const drillIrrLv2 = () => {
        //do nothing
    }

    const drillBasinLv1 = () => {
        setBasinLv2Tn(null)
        console.log('fdfd')
        let geometry = last_mainBasin_geometry;
        console.log(geometry)
        if (geometry) {
            //-- fit extend by estimate bbox --//
            let lngs = [];
            let lats = [];
            let coords = geometry.coordinates[0][0]
            for (let i = 0; i < coords.length; i++) {
                lngs.push(coords[i][0])
                lats.push(coords[i][1])

            }//end for
            //-- fit extend by estimate bbox fixed for some provinces --//
            for (let i = 0; i < geometry.coordinates.length; i++) {
                let lv1 = geometry.coordinates[i];
                for (let j = 0; j < lv1.length; j++) {
                    let lv2 = lv1[j];
                    lngs.push(lv2[0][0])
                    lats.push(lv2[0][1])
                }
            }//end for

            map.bound({ minLon: Math.min(...lngs), minLat: Math.min(...lats), maxLon: Math.max(...lngs), maxLat: Math.max(...lats) }, { padding: { top: 7, bottom: 7 } });
        }
    }

    const drillBasinLv2 = () => {
        //do nothing
        console.log('cdc')
    }







    const onMainBasinChange = (e, v) => {
        setmainBasinValue(v);
        drillMainBasin(v.label, v.mb_code)
    }

    const onProvinceChange = (e, v) => {
        setProvinceValue(v);
        drillThailand(v.label);
    }

    const onRidOfficeChange = (e, v) => {
        last_rid_code = v.code;
        setridOfficeValue(v);
        drillRidOffice(v.label, v.code)
    }

    const onTypeChange = (e, v) => {
        setTypeValue(v);

        if (v.id == 1) {
            console.log(`==== ${v.id}`)
            try { map.Layers.remove(layer_dri_tambon); } catch (error) { }
            try { map.Layers.remove(layer_dri_amphoe); } catch (error) { }
            try { map.Layers.remove(layer_dri_irr); } catch (error) { }
            try { map.Layers.remove(layer_dri_mainBasin); } catch (error) { }
            layer_dri_province = new sphere.Layer('dri:province_with_c_dri', {
                type: sphere.LayerType.WMS,
                url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
                zoomRange: { min: 1, max: 5.5 },
                zIndex: 5,
                opacity: 0.65,
                id: 'layer_dri_province',
            });
            map.Layers.add(layer_dri_province);
            // drillThailand('ประเทศไทย')
            let urlPv = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/province-search?province=`;
            getData(urlPv, 'pv_tn');
        }
        else if (v.id == 2) {
            console.log(`==== ${v.id}`)
            try { map.Layers.remove(layer_dri_tambon); } catch (error) { }
            try { map.Layers.remove(layer_dri_amphoe); } catch (error) { }
            try { map.Layers.remove(layer_dri_province); } catch (error) { }
            try { map.Layers.remove(layer_dri_mainBasin); } catch (error) { }
            setTableHeaderText('สำนักงานชลประทาน')
            layer_dri_irr = new sphere.Layer('dri:irrigation_area_owner_with_c_dri_', {
                type: sphere.LayerType.WMS,
                url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
                zoomRange: { min: 4, max: 15 },
                zIndex: 6,
                opacity: 0.65,
                id: 'layer_dri_irr',
            });
            map.Layers.add(layer_dri_irr);

            let urlIrr = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/irrigation_area_office`;
            getData(urlIrr, 'irr');

        }
        else if (v.id == 3) {
            console.log(`==== ${v.id}`)
            setTableHeaderText('ลุ่มน้ำหลัก');
            try { map.Layers.remove(layer_dri_tambon); } catch (error) { }
            try { map.Layers.remove(layer_dri_amphoe); } catch (error) { }
            try { map.Layers.remove(layer_dri_irr); } catch (error) { }
            try { map.Layers.remove(layer_dri_province); } catch (error) { }

            layer_dri_mainBasin = new sphere.Layer('dri:mainbasin_onwr_basin_with_c_dri_', {
                type: sphere.LayerType.WMS,
                url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
                zoomRange: { min: 4, max: 15 },
                zIndex: 6,
                opacity: 0.65,
                id: 'layer_dri_mainBasin',
            });
            map.Layers.add(layer_dri_mainBasin);

            let urlMainBasin = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/mainbasin_onwr_basin`;
            getData(urlMainBasin, 'basin');

        }
        //end if
    }


    return (
        <Container fluid>
            <Row className="show-grid">
                {/* <Col xs={12} md={2} lg={2}></Col> */}
                <Container>
                    <Card style={{ marginBottom: -3, marginTop: -10, padding: 4, background: '#ffe670', borderRadius: 7 }}>
                        <p style={{ textAlign: 'center', fontFamily: 'Kanit', fontSize: 20, margin: 1 }}>
                            {' '}
                            {`พื้นที่เสี่ยงภัยแล้งราย${tableHeaderText}`}{' '}
                        </p>
                        {/* <p style={{ textAlign: 'center', fontFamily: 'Kanit', fontSize: 20, margin: 1 }}>
                            {' '}
                            {` (สัปดาห์ที่ ${week}) ${firstday} - ${lastday}  `}{' '}
                        </p> */}
                        <p style={{ textAlign: 'center', fontFamily: 'Kanit', fontSize: 20, margin: 1 }}>
                            {' '}
                            {` (สัปดาห์ที่ 27) 1 กรกฎาคม 2567 - 7 กรกฎาคม 2567  `}{' '}
                        </p>
                    </Card>
                </Container>

                {/* <Col xs={12} md={8} lg={8} >
                    <div style={{ marginBottom: 21, padding: 14, background: '#ffe670', borderRadius: 7 }}>
                        <div style={{ fontSize: 24, textAlign: 'center', fontFamily: 'Kanit' }}>
                            {tableHeaderText}
                            {`พื้นที่เสี่ยงภัยแล้งราย${displayKey == 'pv_tn' ? 'จังหวัด' : displayKey == 'ap_tn' ? 'อำเภอ' : 'ตำบล'}`}
                            {`พื้นที่เสี่ยงภัยแล้งราย${tableHeaderText}`}
                        </div>
                        <div style={{ fontSize: 24, textAlign: 'center', fontFamily: 'Kanit' }}>
                            {` ${ (new Date()).toLocaleDateString('th-TH', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } ) }  (สัปดาห์ที่ ${week}) `}
                            {` (สัปดาห์ที่ ${week}) ${firstday} - ${lastday}  `}
                        </div>
                    </div>
                </Col> */}

                {/* <Col xs={12} md={2} lg={2}></Col> */}

                {/* <Col xs={12} md={2} lg={2}></Col> */}
                {/* <Col xs={12} md={8} lg={8} style={{ marginBottom: 21, display: 'flex', fontFamily: 'Kanit' }}>
                    <div style={{ fontSize: 18, background: '#d5ff85', width: '25%', textAlign: 'center', borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}>0 - 50</div>
                    <div style={{ fontSize: 18, background: '#fef9d3', width: '25%', textAlign: 'center' }}>50.1 - 60</div>
                    <div style={{ fontSize: 18, background: '#f9bc42', width: '25%', textAlign: 'center' }}>60.1 - 70</div>
                    <div style={{ fontSize: 18, background: '#ff6600', width: '25%', textAlign: 'center' }}>70.1 - 80</div>
                    <div style={{ fontSize: 18, background: '#cc0000', width: '25%', textAlign: 'center', borderTopRightRadius: 7, borderBottomRightRadius: 7 }}>80.1 - 100</div>
                </Col> */}
                {/* <Col xs={12} md={8} lg={8} style={{ marginBottom: 21, display: 'flex', fontFamily: 'Kanit' }}>
                <div style={{fontSize: 18, background: '#ffea3b', width: '25%', textAlign: 'center' ,borderTopLeftRadius: 7, borderBottomLeftRadius : 7 }}>0 - 50</div>
                <div style={{fontSize: 18, background: '#fac02e', width: '25%', textAlign: 'center'}}>50.1 - 60</div>
                <div style={{fontSize: 18, background: '#fe9701', width: '25%', textAlign: 'center'}}>60.1 - 70</div>
                <div style={{fontSize: 18, background: '#e57373', width: '25%', textAlign: 'center'}}>70.1 - 80</div>
                <div style={{fontSize: 18, background: '#f34236', width: '25%', textAlign: 'center',borderTopRightRadius: 7, borderBottomRightRadius : 7}}>80.1 - 100</div>
            </Col> */}


                {/* <Col xs={12} md={2} lg={2}></Col> */}

                <Col xs={12} md={8} lg={8} style={{ display: 'flex', margin: 14 }}>
                    <Box sx={{ fontSize: 20, fontFamily: 'Kanit', cursor: 'pointer' }} onClick={() => drillThailand('ประเทศไทย')}>
                        <span style={{ background: '#f2f2f2', paddingLeft: 14, paddingRight: 14, borderRadius: 7, fontFamily: 'kanit', fontSize: 18 }} >ประเทศไทย</span>
                    </Box>
                    {
                        pvTn &&
                        <Box sx={{ display: 'inline', fontSize: 20, fontFamily: 'Kanit', cursor: 'pointer' }} onClick={() => drillProvince()}>
                            <span style={{ marginLeft: 7, marginRight: 7, fontSize: 18 }}>{' >> '}</span>
                            <span style={{ background: '#f2f2f2', paddingLeft: 14, paddingRight: 14, borderRadius: 7 }} >{pvTn}</span>
                        </Box>
                    }

                    {
                        apTn &&
                        <Box sx={{ display: 'inline', fontSize: 20, fontFamily: 'Kanit', cursor: 'pointer' }} onClick={() => drillAmphoe()}>
                            <span style={{ marginLeft: 7, marginRight: 7, fontSize: 18 }}>{' >> '}</span>
                            <span style={{ background: '#f2f2f2', paddingLeft: 14, paddingRight: 14, borderRadius: 7 }}>{apTn}</span>
                        </Box>
                    }

                    {
                        tbTn &&
                        <Box sx={{ display: 'inline', fontSize: 20, fontFamily: 'Kanit', cursor: 'pointer' }} onClick={() => drillTambon()} >
                            <span style={{ marginLeft: 7, marginRight: 7, fontSize: 18 }}>{' >> '}</span>
                            <span >{tbTn}</span>
                        </Box>
                    }

                    {
                        IrrLv1Tn &&
                        <Box sx={{ display: 'inline', fontSize: 20, fontFamily: 'Kanit', cursor: 'pointer' }} onClick={() => drillIrrLv1()} >
                            <span style={{ marginLeft: 7, marginRight: 7, fontSize: 18 }}>{' >> '}</span>
                            <span >{IrrLv1Tn}</span>
                        </Box>
                    }

                    {
                        IrrLv2Tn &&
                        <Box sx={{ display: 'inline', fontSize: 20, fontFamily: 'Kanit', cursor: 'pointer' }} onClick={() => drillIrrLv2()} >
                            <span style={{ marginLeft: 7, marginRight: 7, fontSize: 18 }}>{' >> '}</span>
                            <span >{IrrLv2Tn}</span>
                        </Box>
                    }

                    {
                        BasinLv1Tn &&
                        <Box sx={{ display: 'inline', fontSize: 20, fontFamily: 'Kanit', cursor: 'pointer' }} onClick={() => drillBasinLv1()} >
                            <span style={{ marginLeft: 7, marginRight: 7, fontSize: 18 }}>{' >> '}</span>
                            <span >{BasinLv1Tn}</span>
                        </Box>
                    }
                    {
                        BasinLv2Tn &&
                        <Box sx={{ display: 'inline', fontSize: 20, fontFamily: 'Kanit', cursor: 'pointer' }} onClick={() => drillBasinLv2()} >
                            <span style={{ marginLeft: 7, marginRight: 7, fontSize: 18 }}>{' >> '}</span>
                            <span >{BasinLv2Tn}</span>
                        </Box>
                    }

                </Col>

                {/* <Col xs={12} md={2} lg={2}></Col> */}


                <Col xs={12} md={2} lg={2}></Col>

                {/* <Col xs={12} md={1} lg={0}></Col> */}
                {/* <Col xs={12} md={10} lg={12} > */}
                <Col xs={12} md={10} lg={12}>
                    <Row className="show-grid">
                        <Col xs={12} md={12} lg={6} xl={6} xxl={6} >
                            <Box component="main" style={{ width: "100%", height: '90vh' }} id="map-sphere">
                                {/* อาจเอาไว้ใส่พวกเครื่องหมายกลางจอ */}
                                <Tooltip title="คําอธิบายสัญลักษณ์">
                                    <Fab size='small' sx={{
                                        width: '120px',
                                        height: '150px',
                                        minHeight: 'auto',
                                        position: 'absolute',
                                        //top: 144,
                                        right: 10,
                                        bottom: 50,
                                        borderRadius: 1,
                                        color: '#21367C',
                                        backgroundColor: '#FFF',
                                        outline: '2px solid rgba(0, 0, 0, 0.1)',
                                        boxShadow: 0,
                                    }}>
                                        <Image style={{ width: '100%' }} src="/legend/legend_map_dri_vertical_dri.png" />
                                    </Fab>
                                </Tooltip>

                            </Box>
                        </Col>
                        <Col xs={12} md={12} lg={6} xl={6} xxl={6}  >

                            <Autocomplete

                                disableClearable
                                disablePortal id="combo-type"
                                options={type}
                                value={typeValue}
                                onChange={(e, v) => onTypeChange(e, v)}
                                sx={{ width: "100%", fontFamily: 'kanit', display: 'flex', marginBottom: 2 }}
                                renderInput={(params) => <TextField   {...params} label="เลือกประเภทการแสดงผล" />}
                            />

                            {
                                typeValue.id == 1 &&
                                <Autocomplete
                                    disableClearable
                                    disablePortal id="combo-province"
                                    options={provinces}
                                    value={provinceValue}
                                    onChange={(e, v) => onProvinceChange(e, v)}
                                    sx={{ width: "100%", fontFamily: 'kanit', display: 'flex', marginBottom: 2 }}
                                    renderInput={(params) => <TextField  {...params} label="เลือกจังหวัด" />}
                                />
                            }
                            {
                                typeValue.id == 2 &&
                                <Autocomplete
                                    disableClearable
                                    disablePortal id="combo-rid-offices"
                                    options={rid_offices}
                                    value={ridOfficeValue}
                                    onChange={(e, v) => onRidOfficeChange(e, v)}
                                    sx={{ width: "100%", fontFamily: 'kanit', display: 'flex', marginBottom: 2 }}
                                    renderInput={(params) => <TextField  {...params} label="เลือกสำนักงานชลประทาน" />}
                                />
                            }

                            {
                                typeValue.id == 3 &&
                                <Autocomplete
                                    disableClearable
                                    disablePortal id="combo-main-basin"
                                    options={mainBasin}
                                    value={mainBasinValue}
                                    onChange={(e, v) => onMainBasinChange(e, v)}
                                    sx={{ width: "100%", fontFamily: 'kanit', display: 'flex', marginBottom: 2 }}
                                    renderInput={(params) => <TextField  {...params} label="เลือกลุ่มน้ำหลัก" />}
                                />
                            }


                            <TableContainer style={{ height: '100vh', maxHeight: 500 }}
                                sx={{
                                    "&::-webkit-scrollbar": { width: 8, height: 8 },
                                    "&::-webkit-scrollbar-track": { backgroundColor: "#FFFAD7" },
                                    "&::-webkit-scrollbar-thumb": { backgroundColor: "#ffe670", borderRadius: 3 },
                                    "&::-webkit-scrollbar-thumb:hover": { background: "#fac02e" }
                                }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead >
                                        <TableRow >
                                            <TableCell style={{ fontSize: 18, fontFamily: 'Kanit', }} > ลำดับ</TableCell>
                                            <TableCell style={{ fontSize: 18, fontFamily: 'Kanit' }} align="left"> {tableHeaderText}</TableCell>
                                            <TableCell style={{ fontSize: 18, fontFamily: 'Kanit', }} align="left" >DRI</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    {
                                        fetchedData.map((rec, index) => (
                                            <TableBody style={{ borderBottom: "unset" }}>
                                                <TableCell style={{ fontSize: 20, fontFamily: 'Kanit', borderWidth: 0 }} component="th" scope="row" align="center">
                                                    {/* <div style={{ fontSize: 20, fontFamily: 'Kanit' }}> */}
                                                    {index + 1}
                                                    {/* </div> */}
                                                </TableCell>
                                                <TableCell style={{ width: '100%' }} component="th" scope="row" align="left">
                                                    <TableRow style={{ borderBottom: "unset", fontSize: 20, fontFamily: 'Kanit' }}>{rec[displayKey]}</TableRow>
                                                    <TableRow style={{ borderBottom: "unset", color: '#8C8C8C', fontFamily: 'Kanit' }}>{rec.des_}</TableRow>
                                                </TableCell>
                                                <TableCell component="th" scope="row" align="center">
                                                    <div style={{
                                                        width: 54, height: 54, borderRadius: "50%", textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                                        background: rec.mean_ <= 50 ? '#d5ff85' : rec.mean_ <= 60 ? '#fef9d3' : rec.mean_ <= 70 ? '#f9bc42' : rec.mean_ <= 80 ? '#ff6600' : '#cc0000'
                                                    }}>
                                                        <span style={{ color: "#564d2e", fontSize: 20, fontFamily: 'Kanit' }}>{rec.mean_ == null ? '-' : rec.mean_}</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell component="th" scope="row" align="center">
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => drillDown(rec.pv_code, rec.ap_code, rec.tb_code, rec.geometry, rec.pv_tn, rec.ap_tn, rec.tb_tn, rec.code_, rec.office, rec.owner, rec.mb_code, rec.mbasin_t, rec.sb_name_t, rec.sb_code)}
                                                    >
                                                        <ArrowForwardIosIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableBody>
                                        ))
                                    }

                                </Table>

                            </TableContainer>
                        </Col>
                    </Row>
                </Col>

                {/* </Col> */}

                {/* <Col xs={12} md={1} lg={0}></Col> */}
            </Row>
            <Row className="show-grid"><Col xs={12} md={1} lg={0} style={{ height: 28 }}>{'  '}</Col></Row>
        </Container>
    )

}

export default Stat;

const type = [
    { "label": "ขอบเขตการปกครอง", "id": 1 },
    { "label": "พื้นที่ชลประทาน", "id": 2 },
    { "label": "ลุ่มแม่น้ำ", "id": 3 },
]

const provinces = [
    { "label": "ประเทศไทย", "pv_idn": null },
    { "label": "กำแพงเพชร", "pv_idn": 62 },
    { "label": "จันทบุรี", "pv_idn": 22 },
    { "label": "อุบลราชธานี", "pv_idn": 34 },
    { "label": "นครสวรรค์", "pv_idn": 60 },
    { "label": "กรุงเทพมหานคร", "pv_idn": 10 },
    { "label": "สมุทรสาคร", "pv_idn": 74 },
    { "label": "ชุมพร", "pv_idn": 86 },
    { "label": "นครปฐม", "pv_idn": 73 },
    { "label": "เชียงใหม่", "pv_idn": 50 },
    { "label": "ปัตตานี", "pv_idn": 94 },
    { "label": "ชัยภูมิ", "pv_idn": 36 },
    { "label": "พังงา", "pv_idn": 82 },
    { "label": "แพร่", "pv_idn": 54 },
    { "label": "ปทุมธานี", "pv_idn": 13 },
    { "label": "นนทบุรี", "pv_idn": 12 },
    { "label": "มุกดาหาร", "pv_idn": 49 },
    { "label": "พระนครศรีอยุธยา", "pv_idn": 14 },
    { "label": "ยะลา", "pv_idn": 95 },
    { "label": "กระบี่", "pv_idn": 81 },
    { "label": "ร้อยเอ็ด", "pv_idn": 45 },
    { "label": "ศรีสะเกษ", "pv_idn": 33 },
    { "label": "ตาก", "pv_idn": 63 },
    { "label": "บุรีรัมย์", "pv_idn": 31 },
    { "label": "อุดรธานี", "pv_idn": 41 },
    { "label": "หนองบัวลำภู", "pv_idn": 39 },
    { "label": "สกลนคร", "pv_idn": 47 },
    { "label": "ภูเก็ต", "pv_idn": 83 },
    { "label": "อุทัยธานี", "pv_idn": 61 },
    { "label": "หนองคาย", "pv_idn": 43 },
    { "label": "ลำปาง", "pv_idn": 52 },
    { "label": "ตรัง", "pv_idn": 92 },
    { "label": "นครศรีธรรมราช", "pv_idn": 80 },
    { "label": "กาฬสินธุ์", "pv_idn": 46 },
    { "label": "อำนาจเจริญ", "pv_idn": 37 },
    { "label": "อุตรดิตถ์", "pv_idn": 53 },
    { "label": "ฉะเชิงเทรา", "pv_idn": 24 },
    { "label": "มหาสารคาม", "pv_idn": 44 },
    { "label": "สมุทรปราการ", "pv_idn": 11 },
    { "label": "กาญจนบุรี", "pv_idn": 71 },
    { "label": "น่าน", "pv_idn": 55 },
    { "label": "นครพนม", "pv_idn": 48 },
    { "label": "เลย", "pv_idn": 42 },
    { "label": "ลพบุรี", "pv_idn": 16 },
    { "label": "สมุทรสงคราม", "pv_idn": 75 },
    { "label": "ระยอง", "pv_idn": 21 },
    { "label": "ประจวบคีรีขันธ์", "pv_idn": 77 },
    { "label": "สุราษฎร์ธานี", "pv_idn": 84 },
    { "label": "เพชรบุรี", "pv_idn": 76 },
    { "label": "ลำพูน", "pv_idn": 51 },
    { "label": "นครนายก", "pv_idn": 26 },
    { "label": "สุพรรณบุรี", "pv_idn": 72 },
    { "label": "ยโสธร", "pv_idn": 35 },
    { "label": "ขอนแก่น", "pv_idn": 40 },
    { "label": "สระแก้ว", "pv_idn": 27 },
    { "label": "ชัยนาท", "pv_idn": 18 },
    { "label": "นราธิวาส", "pv_idn": 96 },
    { "label": "สระบุรี", "pv_idn": 19 },
    { "label": "สตูล", "pv_idn": 91 },
    { "label": "พะเยา", "pv_idn": 56 },
    { "label": "ราชบุรี", "pv_idn": 70 },
    { "label": "พิษณุโลก", "pv_idn": 65 },
    { "label": "เชียงราย", "pv_idn": 57 },
    { "label": "สงขลา", "pv_idn": 90 },
    { "label": "ชลบุรี", "pv_idn": 20 },
    { "label": "แม่ฮ่องสอน", "pv_idn": 58 },
    { "label": "พัทลุง", "pv_idn": 93 },
    { "label": "อ่างทอง", "pv_idn": 15 },
    { "label": "ตราด", "pv_idn": 23 },
    { "label": "สิงห์บุรี", "pv_idn": 17 },
    { "label": "บึงกาฬ", "pv_idn": 38 },
    { "label": "นครราชสีมา", "pv_idn": 30 },
    { "label": "เพชรบูรณ์", "pv_idn": 67 },
    { "label": "สุโขทัย", "pv_idn": 64 },
    { "label": "ปราจีนบุรี", "pv_idn": 25 },
    { "label": "ระนอง", "pv_idn": 85 },
    { "label": "พิจิตร", "pv_idn": 66 },
    { "label": "สุรินทร์", "pv_idn": 32 }
];

const rid_offices = [
    { "label": "สำนักชลประทานทั่วประเทศ", "code": null },
    { "label": "สำนักชลประทาน 01", "code": 1 },
    { "label": "สำนักชลประทาน 02", "code": 2 },
    { "label": "สำนักชลประทาน 03", "code": 3 },
    { "label": "สำนักชลประทาน 04", "code": 4 },
    { "label": "สำนักชลประทาน 05", "code": 5 },
    { "label": "สำนักชลประทาน 06", "code": 6 },
    { "label": "สำนักชลประทาน 07", "code": 7 },
    { "label": "สำนักชลประทาน 08", "code": 8 },
    { "label": "สำนักชลประทาน 09", "code": 9 },
    { "label": "สำนักชลประทาน 10", "code": 10 },
    { "label": "สำนักชลประทาน 11", "code": 11 },
    { "label": "สำนักชลประทาน 12", "code": 12 },
    { "label": "สำนักชลประทาน 13", "code": 13 },
    { "label": "สำนักชลประทาน 14", "code": 14 },
    { "label": "สำนักชลประทาน 15", "code": 15 },
    { "label": "สำนักชลประทาน 16", "code": 16 },
    { "label": "สำนักชลประทาน 17", "code": 17 },
];

const mainBasin = [
    { "label": "ลุ่มน้ำหลักทั้งหมด", "code": null },
    { "label": "สาละวิน", "mb_code": '01' },
    { "label": "โขงเหนือ", "mb_code": '02' },
    { "label": "โขงตะวันออกเฉียงเหนือ", "mb_code": '03' },
    { "label": "ชี", "mb_code": '04' },
    { "label": "มูล", "mb_code": '05' },
    { "label": "ปิง", "mb_code": '06' },
    { "label": "วัง", "mb_code": '07' },
    { "label": "ยม", "mb_code": '08' },
    { "label": "น่าน", "mb_code": '09' },
    { "label": "เจ้าพระยา", "mb_code": '10' },
    { "label": "สะแกกรัง", "mb_code": '11' },
    { "label": "ป่าสัก", "mb_code": '12' },
    { "label": "ท่าจีน", "mb_code": '13' },
    { "label": "แม่กลอง", "mb_code": '14' },
    { "label": "บางปะกง", "mb_code": '15' },
    { "label": "โตนเลสาป", "mb_code": '16' },
    { "label": "ชายฝั่งอ่าวไทยตะวันออก", "mb_code": '17' },
    { "label": "เพชรบุรี-ประจวบคีรีขันธ์", "mb_code": '18' },
    { "label": "ภาคใต้ฝั่งตะวันออกตอนบน", "mb_code": '19' },
    { "label": "ทะเลสาบสงขลา", "mb_code": '20' },
    { "label": "ภาคใต้ฝั่งตะวันออกตอนล่าง", "mb_code": '21' },
    { "label": "ภาคใต้ฝั่งตะวันตก", "mb_code": '22' }
];
