import React, { useEffect } from "react";
import AuthService from "../services/auth.service";
import ReactGA from "react-ga4";

const Profile = () => {
  const currentUser = AuthService.getCurrentUser();

  //console.log(currentUser)
  useEffect(() => {
    const TRACKING_ID = "G-6VFTN9NWRE"
    ReactGA.initialize(TRACKING_ID)
    ReactGA.send({ hitType: "pageview", page: "/profile", title: "profile" });
 
  }, []);

  return (
    <div className="container">
      <header className="jumbotron">
        <h3 style={{ textAlign: "center", margin: "10px", fontSize: "36px", color: "", fontFamily: 'Kanit' }}>
          ยินดีต้อนรับ<strong>{currentUser.username}</strong>
        </h3>
      </header>
      {/* <p>
        <strong>Token:</strong> {currentUser.accessToken}
      </p> */}
      {/* <p>
        <strong>Id:</strong> {currentUser.id}
      </p> */}
      <p>
        <strong style={{ textAlign: "center",fontWeight:"normal", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>ชื่อผู้ใช้งาน (Username): {currentUser.username}</strong>
      </p>
      <p>
        <strong style={{ textAlign: "center",fontWeight:"normal", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>อีเมล(Email): {currentUser.email}</strong>
      </p>
      <p>
        <strong style={{ textAlign: "center",fontWeight:"normal", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>เบอร์โทร (Phone No): {currentUser.tel}</strong>
      </p>
      {/* <strong>Authorities:</strong>
      <ul>
        {currentUser.roles &&
          currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
      </ul> */}
    </div>
  );
};

export default Profile;
