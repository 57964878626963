import React, { useEffect, useState, useRef } from 'react';
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from 'axios';
import { Button, Badge, Container, Card, CardGroup, ListGroup, Col, Row, Nav, Navbar, NavDropdown, Form, Image } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ReactGA from "react-ga4";






// // CSS import
// import "leaflet/dist/leaflet.css";
import "leaflet.control.opacity/dist/L.Control.Opacity.css";
// JS import
import 'leaflet.control.opacity';


// CSS import
import "leaflet.control.layers.tree/L.Control.Layers.Tree.css";
// JS import
import 'leaflet.control.layers.tree';

// CSS import
import "../components/leaflet.zoomhome.css";
// JS import
import '../components/leaflet.zoomhome.js';




// Import styling 
import "../components/Map.css";
// Import styling 
//import "../components/L.LayerTreeControl.css";


// Import styling 
import "../components/L.LayerTreeControl.js";




let mydate = new Date();
mydate.setDate(mydate.getDate() - 7);
console.log(mydate);

//alert(mydate.getWeek() - 1);
let week = (mydate.getWeek());
console.log(week)


// const monthNames = ["January", "February", "March", "April", "May", "June",
//   "July", "August", "September", "October", "November", "December"
// ];

const monthNames = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
  "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
];


let d = new Date();
console.log(monthNames[d.getMonth()]);

let month = (monthNames[d.getMonth()])
console.log(month);

let month1 = (monthNames[d.getMonth() + 1])
console.log(month1);
let month2 = (monthNames[d.getMonth() + 2])
console.log(month2);
let month3 = (monthNames[d.getMonth() + 3])
console.log(month3);
let month4 = (monthNames[d.getMonth() + 4])
console.log(month4);

let month_ = (parseInt(d.getMonth() + 1))
console.log(month_);
let month1_ = (parseInt(d.getMonth() + 2))
console.log(month1_);
let month2_ = (d.getMonth() + 3)
console.log(month2_);
let month3_ = (d.getMonth() + 4)
console.log(month3_);
let month4_ = (d.getMonth() + 5)
console.log(month4_);




let first_day_of_the_week = new Date(mydate.setDate(mydate.getDate() - 7
  - mydate.getDay() + 1));
let last_day_of_the_week = new Date(mydate.setDate(mydate.getDate()
  - mydate.getDay() + 7));
//console.log(last_day_of_the_week)


let month_1 = new Date(mydate.setDate(mydate.getMonth() + 1));
console.log(month_1)
let month_2 = new Date(mydate.setDate(mydate.getMonth() + 40));
console.log(month_2)
let month_3 = new Date(mydate.setDate(mydate.getMonth() + 40));
console.log(month_3)
let month_4 = new Date(mydate.setDate(mydate.getMonth() + 40));
console.log(month_4)

//console.log(`The first date of the week is: ${first_day_of_the_week}`);
//console.log(`The last day of the week is: ${last_day_of_the_week}`);

var firstday = first_day_of_the_week.toLocaleDateString('th-TH', { month: 'long', })
console.log(firstday)
var lastday = last_day_of_the_week.toLocaleDateString('th-TH', { month: 'long', })
console.log(lastday)

var month_1_ = month_1.toLocaleDateString('th-TH', { month: 'long', })
console.log(month_1_)
var month_2_ = month_2.toLocaleDateString('th-TH', { month: 'long', })
console.log(month_2_)
var month_3_ = month_3.toLocaleDateString('th-TH', { month: 'long', })
console.log(month_3_)
var month_4_ = month_4.toLocaleDateString('th-TH', { month: 'long', })
console.log(month_4_)


const MapLayer = () => {
  const mapContainerRef = useRef(null);
  const map = useRef(null);

  const [lng] = useState(100.7431);
  const [lat] = useState(13.2672);
  const [zoom] = useState(5);

  const [data, setData] = useState(null);
  const [load, loadData] = useState(true);



  const [marker_dams_lat, setDataDams_lat] = useState(null);
  const [marker_dams_lng, setDataDams_lng] = useState(null);

  const [damss, setDataDams] = useState(null);
  const [loadDams, loadDataDams] = useState(true);
  const [marker_dams2, setMarker_dams2] = useState(null);







  const fetchEarthquakeData = async () => {
    const response = await fetch(
      "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dri%3Aprovince_with_c_dri&maxFeatures=10000&outputFormat=application%2Fjson"
    );
    if (load) {
      const data = await response.json();
      console.log(data.features)
      // Set our Data
      setData(data);
      // Tell our code that we don't need to load the data anymore
      loadData(false);
    }
    var geojsongg = data.features
    console.log(geojsongg)
    var geojson = L.geoJson(geojsongg, {
      // style: style,
      // onEachFeature: onEachFeature,
      // pmIgnore: true
    })
    geojson.addTo(map.current).bindPopup("I am a green leaf.");
    var parcel = L.layerGroup();
    geojson.addTo(map)

    setMarker_dams(geojson)
  };

  const fetchDamsData = async (url) => {
    const response = await fetch(url, { method: 'GET' });
    if (loadDams) {
      const damss = await response.json();
      console.log(damss.features)
      // Set our Data
      setDataDams(damss);
      // Tell our code that we don't need to load the data anymore
      loadDataDams(false);

      //L.marker([51.5, -0.09], {icon: greenIcon}).addTo(map).bindPopup("I am a green leaf.");
      //console.log(marker)
      var data_damss = [];

      for (var i = 0; i < damss.features.length; i++) {
        data_damss.push({
          "type": "FeatureCollection",
          "features": [
            {
              "type": "Feature",
              "properties": {
                id: damss.features[i].id,
                name: damss.features[i].properties.name,
                inflow: damss.features[i].properties.inflow,
                outflow: damss.features[i].properties.outflow,
                percent: damss.features[i].properties.percent,
                date: damss.features[i].properties.date,
                active_storage: damss.features[i].properties.active_storage,
                volume: damss.features[i].properties.volume
              },
              "geometry": damss.features[i].geometry
            }
          ]


        })
        //dataname_h.push(responseTable.data.data.jsonFeatures[i].time.split('T')[1].replace(':00+07:00', '') + ' น.');

      }
      console.log(data_damss)

      console.log(data_damss.length)

      // var geojsonMarkerOptions = {
      //   radius: 8,
      //   fillColor: "#ff7800",
      //   color: "#000",
      //   weight: 1,
      //   opacity: 1,
      //   fillOpacity: 0.8
      // };

      // L.geoJSON(data_temp_min, {
      //   pointToLayer: function (feature, latlng) {
      //     return L.circleMarker(latlng, geojsonMarkerOptions);
      //   }
      // }).addTo(map.current);

      setMarker_dams2(data_damss)
      //L.geoJson(geo).addTo(map.current);
    }
  };






  // const formData = new FormData();
  // formData.append("date", '2024-01-23');

  // axios.post(url_draw, formData, {
  //   headers: {
  //     'Content-Type': 'multipart/form-data'
  //   }
  // }).then
  //   (response => {
  //     setResponse(response.data.success);
  //     //setInput({ name: '', email: '' });
  //     setFetgeomInsert(0);
  //     setName('');
  //     settype('');
  //     setpat('');
  //     setFetarea_polygon(0);
  //     // Swal.fire({
  //     //   title: 'นําเข้าแปลงสําเร็จ',
  //     //   icon: 'success'
  //     //   //setStatus(resp.status === 200 ? "นําเข้าแปลงสําเร็จ!" : "Error."),   

  //     // })
  //     refreshPage()
  //   })
  //   .catch(error => {
  //     setErrors(error.response.data.errors);
  //   });





  function onEachFeature(f, l) {
    var popupContent =
      '\
              <style> \
            input[type=text] {\
              color :#DC143C; \
            }\
            </style>\
            <style> \
            input[type=number] {\
              color :#DC143C; \
            }\
            </style>\
                <legend><h6 style="font-weight: bold">รายละเอียดข้อมูล</h6></legend>\
                <form  role="form" class="form-row" enctype="multipart/form-data">\
                <div class="col">\
                  <label for="exampleInputEmail1">จังหวัด:</label>\
                  <input type="text"  name="zoning" readonly value="' + f.properties.pv_tn + '" disabled>\
                </div>\
                <div class="col">\
                  <label for="exampleInputPassword1">โอกาสได้รับความเสี่ยงภัยแล้ง:</label>\
                  <input type="text"  name="name_suit" readonly value="' + f.properties.mean + '%' + '" disabled>\
                </div>\
                </div>\
          </form>'

    l.bindPopup(popupContent);
  }

  function onEachFeature2(f, l) {
    const date = new Date()
    const result = date.toLocaleDateString('th-TH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    var popupContent =
      '<div class="col" style="font-size: 12px;font-weight: normal;\
    width: max-content; text-align: left; font-family:' + "'Kanit'" + ', sans-serif">\
    <label style="font-size: 14px;" for="exampleInputPassword1">'+ f.properties.name + '</label>\
    <label for="exampleInputPassword1">ปริมาณน้ำในอ่าง : '+ f.properties.volume + ' ล้าน ลบ.ม.</label>\
    <label for="exampleInputPassword1">ปริมาณน้ำในอ่าง: '+ f.properties.percent + ' %</label>\
    <label for="exampleInputPassword1">ความจุ: '+ f.properties.active_storage + ' ล้าน ลบ.ม.</label>\
    <label for="exampleInputPassword1">ปริมาณน้ำไหลเข้า: '+ f.properties.inflow + ' ล้าน ลบ.ม.</label>\
    <label for="exampleInputPassword1">ปริมาณน้ำระบาย: '+ f.properties.outflow + ' ล้าน ลบ.ม.</label>\
    <label for="exampleInputPassword1">วันที่: '+ result + '</label>\
  </div>'
    l.bindPopup(popupContent);
  }

  function style2(feature) {
    return {
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7,
      fillColor: getColor(feature.properties.mean)
    };
  }

  function style(feature) {
    return {
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7,
      fillColor: getColor(feature.properties.mean)
    };
  }

  //slymbology
  function getColor(d) {
    return d > 100 ? '#cc0000' :
      d > 80 ? '#cc0000' :
        d > 70 ? '#ff6600' :
          d > 60 ? '#f9bc42' :
            d > 50 ? '#fef9d3' :
              '#d5ff85';
  }




  var osm = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '© OpenStreetMap'
  });

  var osmHOT = L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '© OpenStreetMap contributors, Tiles style by Humanitarian OpenStreetMap Team hosted by OpenStreetMap France'
  });

  //sphere_streets
  var sphere_streets = L.tileLayer('https://basemap.sphere.gistda.or.th/tiles/sphere_streets/EPSG3857/{z}/{x}/{y}.png?key=0BAF97C307C24B28A7E19CE1D9B1DE88', {
    maxZoom: 19,
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  });
  //sphere_hybrid 
  var sphere_hybrid = L.tileLayer('https://basemap.sphere.gistda.or.th/tiles/sphere_hybrid/EPSG3857/{z}/{x}/{y}.jpeg?key=0BAF97C307C24B28A7E19CE1D9B1DE88', {
    maxZoom: 19,
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  });

  //https://basemap.sphere.gistda.or.th/tiles/thailand_images/EPSG3857/{z}/{x}/{y}.jpeg?key=0BAF97C307C24B28A7E19CE1D9B1DE88

  const province = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: 'dri:province	',
    format: 'image/png',
    transparent: true,
    opacity: 0.65,
    attribution: "myattribution"
  });

  const amphoe = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: 'dri:amphoe',
    format: 'image/png',
    transparent: true,
    opacity: 0.65,
    attribution: "myattribution"
  });

  const tambon = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: 'dri:tambon',
    format: 'image/png',
    transparent: true,
    opacity: 0.65,
    attribution: "myattribution"
  });


  const basin = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: 'dri:mainbasin_onwr_basin',
    format: 'image/png',
    transparent: true,
    attribution: "myattribution"
  });

  const irrigation_area_office = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: 'dri:irrigation_area_office',
    format: 'image/png',
    transparent: true,
    attribution: "myattribution"
  });


  const dri_week = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: `dri:c_dri_w${week}`,
    format: 'image/png',
    transparent: true,
    attribution: "myattribution"
  });

  // L.tileLayer.betterWms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
  //   layers: `dri:c_dri_w${week}`,
  //   transparent: true,
  //   format: 'image/png'
  // })
  //.addTo(map.current);

  const dsi_month = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: `dri:dsi_m${month_}`,
    format: 'image/png',
    transparent: true,
    attribution: "myattribution"
  });

  const ssm_month = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: `dri:ssm_m${month_}`,
    format: 'image/png',
    transparent: true,
    attribution: "myattribution"
  });

  const dri_month_f1 = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: `dri:dri_f_m${month1_}`,
    format: 'image/png',
    transparent: true,
    attribution: "myattribution"
  });

  const dri_month_f2 = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: `dri:dri_f_m${month2_}`,
    format: 'image/png',
    transparent: true,
    attribution: "myattribution"
  });

  const dri_month_f3 = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: `dri:dri_f_m${month3_}`,
    format: 'image/png',
    transparent: true,
    attribution: "myattribution"
  });

  const dri_month_f4 = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: `dri:dri_f_m${month4_}`,
    format: 'image/png',
    transparent: true,
    attribution: "myattribution"
  });

  const dri_zonal_tambon = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: `dri:tambon_with_c_dri`,
    format: 'image/png',
    transparent: true,
    attribution: "myattribution"
  });

  const dri_zonal_amphoe = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: `dri:amphoe_with_c_dri`,
    format: 'image/png',
    transparent: true,
    attribution: "myattribution"
  });

  const dri_zonal_provine = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?", {
    layers: `dri:province_with_c_dri`,
    format: 'image/png',
    transparent: true,
    attribution: "myattribution"
  });

  // const pm25 = L.tileLayer.wms("https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/pm25_hourly_raster/wms?", {
  //   layers: `0`,
  //   format: 'image/png',
  //   transparent: true,
  //   attribution: "myattribution"
  // });

  //stype Geojson
  // let geojsonMarkerOptions = {
  //   radius: 8,
  //   fillColor: "#ff7800",
  //   color: "#000",
  //   weight: 1,
  //   opacity: 1,
  //   fillOpacity: 0.8
  // };

  //feature.properties.mean

  // let sym = marker_dams2.feature
  // console.log(sym)


  //.addTo(map.current);

  //const dams = L.marker({ lon: marker_dams_lat, lat: marker_dams_lng })


  // const response =   fetch('https://app.rid.go.th/reservoir/api/dams', { method: 'POST' })
  //   .then(function (response) {
  //     return response.json()
  //   })
  //   .then(function (data) {
  //     //var testt = data.features
  //     //L.geoJson(testt).addTo(map.current);
  //     console.log(data)
  //   })


  //const test = L.geoJSON()



  //const dams = ([marker_dams_lat, marker_dams_lng])
  //console.log(dams)








  // //96.0,5.10962911,106.87235903,21.0
  // var imageUrl = L.imageOverlay('https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?service=WMS&version=1.1.0&request=GetMap&layers=dri%3Ac_dri_w2&bbox=96.0%2C5.10962911%2C106.87235903%2C21.0&width=525&height=768&srs=EPSG%3A4326&styles=&format=image%2Fpng',
  //   [[5.10962911, 96.0], [21.0, 106.87235903]], {
  //     zIndex: 800,
  //     opacity: 1.0

  // })



  // var imageUrl = 'https://maps.lib.utexas.edu/maps/historical/newark_nj_1922.jpg',
  //   imageBounds = [[5.10962911, 96.0], [21.0, 106.87235903]]
  // L.imageOverlay(imageUrl, imageBounds)
  //.addTo(map.current);



  var baseMaps = {
    "OpenStreetMap": osm,
    "OpenStreetMap.HOT": osmHOT
  };

  var overlayMaps = {
    "DRI": dri_week,
  };







  useEffect(() => {


    setMarker_dams2(marker_dams2)

    console.log(marker_dams2)


    const TRACKING_ID = "G-6VFTN9NWRE"
    ReactGA.initialize(TRACKING_ID)
    ReactGA.send({ hitType: "pageview", page: "/MapLayer", title: "MapLayer" });








    // for (var i = 0; i < marker_dams2.length; i++) {
    //   let dam = marker_dams2[i];
    //   // let detail = `<span style='font-weight: bold; font-size: 12px'>วันที่ : </span> <span>${dam.DMD_Date}</span><br>`;
    //  console.log(dam)
    // }

    // let greenIcon = L.icon({
    //   iconUrl: '/color-dam1.png',
    //   shadowUrl: '/color-dam1.png',
    //   //iconSize: [18, 20], // size of the icon
    // })

    const marker_dam = L.geoJSON(marker_dams2, {
      pointToLayer: function (feature, latlng) {
        let dam = feature.properties.percent;
        //console.log(dam)
        if (parseFloat(dam) > 100) {
          let greenIcon = new L.Icon({
            iconUrl: '/color-dam5.png',
            iconSize: [20, 20],
          })
          return L.marker(latlng, { icon: greenIcon });
        }
        else if (parseFloat(dam) > 80.1) {
          let greenIcon = new L.Icon({
            iconUrl: '/color-dam4.png',
            iconSize: [20, 20],
          })
          return L.marker(latlng, { icon: greenIcon });
        }
        else if (parseFloat(dam) > 50.1) {
          let greenIcon = new L.Icon({
            iconUrl: '/color-dam3.png',
            iconSize: [20, 20],
          })
          return L.marker(latlng, { icon: greenIcon });
        }
        else if (parseFloat(dam) > 30.1) {
          let greenIcon = new L.Icon({
            iconUrl: '/color-dam2.png',
            iconSize: [20, 20],
          })
          return L.marker(latlng, { icon: greenIcon });
        }
        else if (parseFloat(dam) <= 30.1) {
          let greenIcon = new L.Icon({
            iconUrl: '/color-dam1.png',
            iconSize: [20, 20],
          })
          return L.marker(latlng, { icon: greenIcon });
        }
        else {
          let greenIcon = new L.Icon({
            iconUrl: '/color-dam1.png',
            iconSize: [20, 20],
          })
          return L.marker(latlng, { icon: greenIcon });
        }
      },
      onEachFeature: onEachFeature2
    })







    // L.marker([100, 13], {icon: greenIcon}).addTo(map.current);
    //fetchDamsData('')
    //fetchDamsData2(urlProvince)
    //fetchEarthquakeData()
    map.current = L.map(mapContainerRef.current,
      {
        zoomSnap: 0.25,
        zoomControl: false
      }
    )
    map.current.setView([lat, lng], 5.7);


    // L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    //   attribution: "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors",
    // }).addTo(map.current);

    var sphere_streets = L.tileLayer('https://basemap.sphere.gistda.or.th/tiles/sphere_streets/EPSG3857/{z}/{x}/{y}.png?key=0BAF97C307C24B28A7E19CE1D9B1DE88', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map.current);

    fetchDamsData(`https://app.rid.go.th/reservoir/api/damsGeojson`);


    //.addTo(map.current);

    // L.control.layers(baseMaps, overlayMaps, {
    //   collapsed: true,
    // }).addTo(map.current);
    // //OpacityControl
    // L.control.opacity(overlayMaps, {
    //   collapsed: false,
    //   position: 'topleft'
    //   //label: 'Layers Opacity',
    // })
    //   .addTo(map.current);

    //legend
    var legend1 = L.control({
      position: 'bottomleft'
    });
    legend1.onAdd = ['my marvellous first legend'];


    var baseTree = [
      {
        label: 'แผนที่ฐาน',
        children: [
          { label: 'แผนที่ถนน', layer: sphere_streets, },
          { label: 'แผนที่ภูมิประเทศ', layer: osmHOT },
          { label: 'แผนที่ภาพถ่ายดาวเทียม', layer: sphere_hybrid },
        /* ... */,

        ]
      },
    ];

    var overlaysTree = [
      {
        //label:`<button type="button" class="btn btn-secondary btn-sm">ชั้นข้อมูล</button>`,
        //label: `<span class = "leaflet-layerstree-header-name" style='font-weight: bold; font-size: 12px'>วันที่ : </span> <span></span><br>` ,

        label: 'ชั้นข้อมูล',

        //selectAllCheckbox: 'Un/select all',
        children: [
          {
            label: `
            <span style='font-weight: bold; font-size: 14px;background-color: '' class="" data-bs-toggle="tooltip" data-bs-placement="top" title="พื้นที่เสี่ยงภัยแล้ง(รายพื้นที่)">พื้นที่เสี่ยงภัยแล้ง(รายพื้นที่)</span>`,
            //selectAllCheckbox: true,
            children: [
              {
                label: `พื้นที่เสี่ยงภัยแล้งรายจังหวัด <span style='background-color:#fffff3;font-weight: normal; font-size: 12.5px' class="">(สัปดาห์ที่27)</span>`, layer: dri_zonal_provine,
                // label: `พื้นที่เสี่ยงภัยแล้งรายจังหวัด <span style='background-color:#fffff3;font-weight: normal; font-size: 12.5px' class="">(สัปดาห์ที่${week})</span>`, layer: dri_zonal_provine,
                children: [
                  {
                    collapsed: true,
                    label: `<span class = "leaflet-layerstree-header-name" style='font-weight: normal; font-size: 12px'>คําอธิบายสัญลักษณ์</span> <span></span><br>`,
                    children: [
                      {
                        label: `<figure class="figure">
                  <img src="legend/legend_map_dri_vertical_dri.png" class="figure-img img-fluid rounded" alt="...">`,
                      }
                    ]
                  },

                ]


              },
              {
                label: `พื้นที่เสี่ยงภัยแล้งรายอําเภอ (สัปดาห์ที่27)`, layer: dri_zonal_amphoe,
                //label: `พื้นที่เสี่ยงภัยแล้งรายอําเภอ (สัปดาห์ที่${week})`, layer: dri_zonal_amphoe,
                children: [
                  {
                    collapsed: true,
                    label: `<span class = "leaflet-layerstree-header-name" style='font-weight: normal; font-size: 12px'>คําอธิบายสัญลักษณ์</span> <span></span><br>`,
                    children: [
                      {
                        label: `<figure class="figure">
                  <img src="legend/legend_map_dri_vertical_dri.png" class="figure-img img-fluid rounded" alt="...">
                  <figcaption class="figure-caption text-end">A caption for the above image.</figcaption>
                </figure>`,
                      }
                    ]
                  },

                ]


              },
              {
                //label: `พื้นที่เสี่ยงภัยแล้งรายตําบล (สัปดาห์ที่${week})`, layer: dri_zonal_tambon.addTo(map.current),
                label: `พื้นที่เสี่ยงภัยแล้งรายตําบล (สัปดาห์ที่27)`, layer: dri_zonal_tambon.addTo(map.current),
                children: [
                  {
                    collapsed: true,
                    label: `<span class = "leaflet-layerstree-header-name" style='font-weight: normal; font-size: 12px'>คําอธิบายสัญลักษณ์</span> <span></span><br>`,
                    children: [
                      {
                        label: `<figure class="figure">
                  <img src="legend/legend_map_dri_vertical_dri.png" class="figure-img img-fluid rounded" alt="...">
                  <figcaption class="figure-caption text-end">A caption for the above image.</figcaption>
                </figure>`,
                      }
                    ]
                  },

                ]
              },
            ]
          },
          {
            label: ' แผนที่พื้นที่เสี่ยงภัยแล้ง',
            //selectAllCheckbox: true,
            children: [
              {
                //label: `พื้นที่เสี่ยงภัยแล้ง(สัปดาห์ที่${week})`, layer: dri_week,
                label: `พื้นที่เสี่ยงภัยแล้ง(สัปดาห์ที่27)`, layer: dri_week,
                children: [
                  {
                    collapsed: true,
                    label: `<span class = "leaflet-layerstree-header-name" style='font-weight: normal; font-size: 12px'>คําอธิบายสัญลักษณ์</span> <span></span><br>`,
                    children: [
                      {
                        label: `<figure class="figure">
                  <img src="legend/legend_map_dri_vertical_dri.png" class="figure-img img-fluid rounded" alt="...">
                  <figcaption class="figure-caption text-end">A caption for the above image.</figcaption>
                </figure>`,
                      }
                    ]
                  },

                ]


              },
              {
                label: `พื้นที่เสี่ยงภัยแล้งต่อเนื่อง(เดือน${month})`, layer: dsi_month,
                children: [
                  {
                    collapsed: true,
                    label: `<span class = "leaflet-layerstree-header-name" style='font-weight: normal; font-size: 12px'>คําอธิบายสัญลักษณ์</span> <span></span><br>`,
                    children: [
                      {
                        label: `<figure class="figure">
                  <img src="legend/legend_map_dri_vertical_dsi.png" class="figure-img img-fluid rounded" alt="...">
                  <figcaption class="figure-caption text-end">A caption for the above image.</figcaption>
                </figure>`,
                      }
                    ]
                  },
                ]
              },
              {
                label: `ความชื้นในดิน(เดือน${month})`, layer: ssm_month,
                children: [
                  {
                    collapsed: true,
                    label: `<span class = "leaflet-layerstree-header-name" style='font-weight: normal; font-size: 12px'>คําอธิบายสัญลักษณ์</span> <span></span><br>`,
                    children: [
                      {
                        label: `<figure class="figure">
                <img src="legend/legend_map_dri_vertical_ssm.png" class="figure-img img-fluid rounded" alt="...">
              </figure>`,
                      }
                    ]
                  },
                ]
              },
              {
                label: 'คาดการณ์พื้นที่เสี่ยงภัยแล้ง (4เดือน)',
                children: [
                  {
                    label: `เดือน${month1}`, layer: dri_month_f1,
                    children: [
                      {
                        collapsed: true,
                        label: `<span class = "leaflet-layerstree-header-name" style='font-weight: normal; font-size: 12px'>คําอธิบายสัญลักษณ์</span> <span></span><br>`,
                        children: [
                          {
                            label: `<figure class="figure">
                    <img src="/legend.png" class="figure-img img-fluid rounded" alt="...">
                  </figure>`,
                          }
                        ]
                      },
                    ]
                  },
                  {
                    label: `เดือน${month2}`, layer: dri_month_f2,
                    children: [
                      {
                        collapsed: true,
                        label: `<span class = "leaflet-layerstree-header-name" style='font-weight: normal; font-size: 12px'>คําอธิบายสัญลักษณ์</span> <span></span><br>`,
                        children: [
                          {
                            label: `<figure class="figure">
                      <img src="/legend.png" class="figure-img img-fluid rounded" alt="...">
                    </figure>`,
                          }
                        ]
                      },
                    ]
                  },
                  {
                    label: `เดือน${month3}`, layer: dri_month_f3,
                    children: [
                      {
                        collapsed: true,
                        label: `<span class = "leaflet-layerstree-header-name" style='font-weight: normal; font-size: 12px'>คําอธิบายสัญลักษณ์</span> <span></span><br>`,
                        children: [
                          {
                            label: `<figure class="figure">
                    <img src="/legend.png" class="figure-img img-fluid rounded" alt="...">
                  </figure>`,
                          }
                        ]
                      },
                    ]
                  },
                  {
                    label: `เดือน${month4}`, layer: dri_month_f4,
                    children: [
                      {
                        collapsed: true,
                        label: `<span class = "leaflet-layerstree-header-name" style='font-weight: normal; font-size: 12px'>คําอธิบายสัญลักษณ์</span> <span></span><br>`,
                        children: [
                          {
                            label: `<figure class="figure">
                    <img src="/legend.png" class="figure-img img-fluid rounded" alt="...">
                  </figure>`,
                          }
                        ]
                      },
                    ]
                  },
                ]
              },
            ]
          },
          {
            label: 'ขอบเขตการปกครอง',
            name: 'dsdsdsd',
            children: [

              { label: 'จังหวัด', layer: province.addTo(map.current), },
              { label: 'อําเภอ', layer: amphoe, },
              { label: 'ตําบล', layer: tambon, },

            ]
          },
          {
            label: 'ขอบเขตลุ่มน้ำ',
            //selectAllCheckbox: true,
            children: [
              { label: 'ขอบเขตลุ่มน้ำหลัก', layer: basin, }
            ]
          },
          {
            label: 'ขอบเขตโครงการชลประทาน',
            //selectAllCheckbox: true,
            children: [
              { label: 'ขอบเขตโครงการชลประทาน', layer: irrigation_area_office, }
            ]
          },
          {
            label: 'ข้อมูลสถานการณ์น้ำในอ่าง (กรมชลประทาน)',
            //selectAllCheckbox: true,
            children: [
              {
                label: 'อ่างขนาดใหญ่', layer: marker_dam.addTo(map.current),
                children: [
                  {
                    collapsed: true,
                    label: `<span class = "leaflet-layerstree-header-name" style='font-weight: normal; font-size: 12px'>คําอธิบายสัญลักษณ์</span> <span></span><br>
                    `,
                    children: [
                      {
                        label: `<figure class="figure">
                <img src="legend/legend_map_dri_vertical_dam.png" class="figure-img img-fluid rounded" alt="...">`,

                      },

                    ]
                  },
                ]
              },
              // { label: 'อ่างขนาดกลาง', layer: '', }
            ]
          },
        ],
      },
      // {
      //   label: 'พื้นที่เสี่ยงภัยแล้ง',
      //   children: [
      //     { label: 'DRI', layer: dri_week, },
      //     { label: 'DSI', layer: irrigation_area_office, },
      //     { label: 'SSM', layer: irrigation_area_office, },
      //     { label: 'DRI Forecast', layer: irrigation_area_office, },
      //   /* ... */,
      //   ]
      // },
    ]

    L.control.zoomBar({ position: 'topleft' }).addTo(map.current);
    L.control.layers.tree(baseTree, overlaysTree, {
      position: 'topright',
      collapsed: false,
      openedSymbol: '&#x1F4C2;',
      closedSymbol: '&#128269;',
      labelIsSelector: 'both'
    }).addTo(map.current);




    return () => {
      map.current.remove();
    };

  }, [lat, lng, zoom, data, load, marker_dams2]);




  return (


    <div class="row">
      <div className="map-container" ref={mapContainerRef}
      />
    </div>




  );
};

export default MapLayer;